<template>
  <div id="treatmentCenterTab" class="form-horizontal">
    <div class="title-bar">
      <div>
      <div  class="pull-right center-float" v-if="!formActive"><Button id="treatmentCenterEditBtn" @click="editDetails" :label="$t(`edit_details`)"></Button></div>
      <div  class="pull-right center-float" v-if="formActive"><Button id="treatmentCenterCancelBtn" color="secondary" @click="cancel" :label="$t(`cancel`)"></Button></div>
      </div>
    </div>

    <Form key="TreatmentCentersTab" :name="'TreatmentCentersTab'" :isEditing="formActive" :loadFormData="true" :getFormUrlParams="'idName=PATIENT&id=' + patientId" :saveFormUrlParams="'?episodeId=' + patientId" />
  </div>
</template>

<script>
import Form from '../../../../shared/components/Form.vue'
import Button from '../../../../shared/components/Button.vue'
import EventBus from '@/app/shared/event-bus.js'
import { mapState } from 'vuex'

export default {
  name: 'TreatmentCentersTab',
  data: self => ({
    formActive: false
  }),
  created () {
    EventBus.$on('FORM_SAVE_SUCCESS', this.formSavedSuccessfully)
  },
  components: {
    Form, Button
  },
  computed: {
    ...mapState('Form', ['isLoading']),
    ...mapState('Patient', ['patientId'])
  },
  methods: {
    editDetails () {
      if (this.isLoading) {
        return
      }
      this.formActive = true
    },
    cancel () {
      if (this.isLoading) {
        return
      }
      this.formActive = false
    },
    back () {
      if (!this.isLoading) {
        this.formActive = false
      }
    },
    formSavedSuccessfully (formName) {
      this.back()
    }
  }
}
</script>

<style>
#treatmentCenterTab {
  background-color: var(--background-color-primary-light-dark);
  padding:20px 0 10px 0;
}
.center-float {
  margin-right: 20px;
}
.pull-right {
  justify-content: flex-end;
  display: flex;
}
.text-no-margin {
  margin: auto;
}
</style>
